import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import authservice from './Authentication/service/authservice';
import { connect } from 'react-redux';
import PageVisibility from 'react-page-visibility'
import './advertisements/styles/popup.css';
import { TitleHeading } from './UIElements/headings/TitleHeading';
import { ButtonStyle } from './UIElements/Buttons/ButtonStyle';
import axios from 'axios';
import { container, notifyUser, type } from './utility/notifyUser';

class Upgrade extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: true,
            skipPopup: false,
            closeButton: false,
            selectedOption: '',
            prepaidAccount: false,
            monthlyPrepaidAccount: false,
            radioError: '',
            sendOtp: false
        }
    }

    componentDidMount() {
        //console.log(this.props)
        // if (this.props.user.status === 'OTP_VERIFICATION') {
        //     // Use history.push to navigate to the OTP verification page
        //     this.props.history.push({
        //         pathname: `/otp/${this.props.user.id}`,
        //         search: `?auth_mod=${this.props.user.mobileNumber}`,
        //         state: { email: this.props.user.email }
        //     });

        //     // Call the otpVerify function after navigation
        //     this.otpVerify();
        // }
        this.props.notifyPopupOpen(true)
    }

    renderSkip = () => {
        axios.post(process.env.REACT_APP_BACKEND_URL + `/abmgr/auth/update-user-status/${this.props.user.id}`).then(response => {
            if (response.data) {
                this.setState({
                    open: false,
                    skipPopup: false
                })
                this.props.history.push('/login/user');
            }
        })
    }

    // handleOptionChange = (changeEvent) => {
    //     const value = changeEvent.target ? changeEvent.target.value : '';
    //     this.setState({ prepaidAccount: value === 'option1', monthlyPrepaidAccount: value === 'option2', selectedOption: value }, () => {
    //         this.setState({ radioError: this.state.selectedOption ? '' : 'Please Select one option.' }) 
    //     })
    // }

    handleOptionChange = (changeEvent) => {
        const value = changeEvent.target.value;
        this.setState({
            prepaidAccount: value === 'option1',
            monthlyPrepaidAccount: value === 'option2',
            selectedOption: value,
            radioError: value ? '' : 'Please Select one option.'
        });
    }

    // otpVerify = () => {
    //     const userId = authservice.getCurrentUserid()
    //     axios.get(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/resendotp/' + this.props.user.id).then(response => {
    //         if (response.status == 200)
    //             notifyUser("OTP Sent!", "OTP is sent to your registered mobile number", type.SUCCESS, container.BOTTOM_RIGHT)
    //     }).catch(err => {
    //         if (err.response)
    //             notifyUser("OTP Error", "Something went wrong while sending OTP", type.DANGER, container.BOTTOM_RIGHT)
    //     })
    // }

    render() {
        return (

            <PageVisibility>
                <>
                    <div className='locateDesktopPopup'>
                        {this.props.user.message === 'PREPAID' || this.props.user.message === 'YEARLY_PREPAID' ?
                            <>
                                <Popup
                                    open={this.state.open}
                                    overlayStyle={{ background: 'rgba(0,0,0,0.95)' }}
                                    closeOnDocumentClick={false}
                                >
                                    <div className='popup_height'>
                                        <div className='popup_innerheight' key="message">
                                            <div className="form-row">
                                                <div className="col-md-12 text-center location_padding">
                                                    <TitleHeading>
                                                        Make Payment
                                                    </TitleHeading>
                                                </div>
                                                <div className="col-md-12 text-center">
                                                    <div className="col-md-12 text-left intermediate_popup">
                                                        <p>Dear AajuBaajuian,</p>
                                                        <p>Please click on the pay now button to pay the subscription amount to login.</p>
                                                        <div className="row intermediate_popup_padding">
                                                            <div className="col-sm-12 text-center">
                                                                <ButtonStyle type="submit" className="btn" handleClick={() => this.setState({ open: false })}><Link to={{
                                                                    pathname: `/order/${this.props.user.id}`,
                                                                    state: { payfor: 'membership' }
                                                                }} className='payment_text'>PAY NOW</Link></ButtonStyle>
                                                            </div>
                                                        </div>
                                                        <p><strong>Note:</strong> Please do not click the PAY NOW button if you have already made the payment and the payment was successful.
                                                            Send the payment screenshot to 8341370819 for us to check and activate the account.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Popup>
                            </> : this.props.user.message === 'HALF_YEARLY_PREPAID' && this.props.user.accountType === 'REGISTERED' ?
                                <>
                                    <Popup
                                        open={this.state.open}
                                        overlayStyle={{ background: 'rgba(0,0,0,0.95)' }}
                                        closeOnDocumentClick={false}
                                    >
                                        <div className='popup_height'>
                                            <div className='popup_innerheight' key="message">
                                                <div className="form-row">
                                                    <div className="col-md-12 text-center location_padding">
                                                        <TitleHeading>
                                                            Make Payment
                                                        </TitleHeading>
                                                    </div>
                                                    <div className="col-md-12 text-center">
                                                        <div className="col-md-12 text-left intermediate_popup">
                                                            <p>Dear AajuBaajuian,</p>
                                                            <p>Please click on the pay now button to pay the half yearly subscription amount to login.</p>

                                                            <div className="row intermediate_popup_padding">
                                                                {/* <div className="col-sm-2 text-right"></div> */}
                                                                <div className="col-sm-12 text-center">
                                                                    <ButtonStyle type="submit" className="btn" handleClick={() => this.setState({ open: false })}><Link to={{
                                                                        pathname: `/order/${this.props.user.id}`,
                                                                        state: { payfor: 'membership' }
                                                                    }} className='payment_text'>PAY NOW</Link></ButtonStyle>
                                                                </div>
                                                            </div>
                                                            <p><strong>Note:</strong> Please do not click the PAY NOW button if you have already made the payment and the payment was successful.
                                                                Send the payment screenshot to 8341370819 for us to check and activate the account.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Popup>
                                </> :
                                <Popup
                                    open={this.state.open}
                                    overlayStyle={{ background: 'rgba(0,0,0,0.95)' }}
                                    closeOnDocumentClick={false}
                                >
                                    {this.props.user.accountType !== 'REGISTERED' && this.props.user.message === 'MONTHLY_PREPAID' ?
                                        <div className='popup_height'>
                                            <div className='popup_innerheight' key="message">
                                                <div className="form-row">
                                                    <div className="col-md-12 text-center location_padding">
                                                        <TitleHeading>
                                                            Account Expired, Please Renew
                                                        </TitleHeading>
                                                    </div>
                                                    <div className="col-md-12 text-center">
                                                        <div className="col-md-12 text-left popup_upgrade">
                                                            <p>Dear AajuBaajuian,</p>
                                                            <p>Account Expired! Please Renew to Continue.</p>
                                                            {this.props.user.gracePeriodExpiryDate !== null ?
                                                                <p>Your Loyaly Earnings will be expired on {this.props.user.gracePeriodExpiryDate} .</p> : null}
                                                            <p>You will loose your Accumulated Loyalty Earning if you do not renew within 3 days from the date of expiry. The Loyalty income will become Zero.</p>
                                                            <div className='row intermediate_popup_padding'>
                                                                <div className="row col-12 text-left">
                                                                    <div className="form-row col-12 col-md-6">
                                                                        <label className='container_radio col-12 col-md-12 d-flex'>
                                                                            <div className='col-1 col-md-1'><input type="checkbox" name="radio" value="option1" checked={this.state.selectedOption === 'option1'} onChange={this.handleOptionChange}  />
                                                                                <span className="checkmark"></span></div>

                                                                            <div className='col-11 col-md-11 p-0'><span className='text-left word-break'>Annual</span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-row col-12 col-md-6">
                                                                        <label className='container_radio col-12 col-md-12 d-flex'>
                                                                            <div className='col-1 col-md-1'><input type="checkbox" name="radio" value="option2" checked={this.state.selectedOption === 'option2'} onChange={this.handleOptionChange} />
                                                                                <span className="checkmark"></span></div>

                                                                            <div className='col-11 col-md-11 p-0'><span className='text-left'>Monthly</span></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row intermediate_popup_padding">
                                                                <div className="col-sm-2 text-right"></div>

                                                                <div className="col-sm-4 text-center">
                                                                    <ButtonStyle type="submit" className="btn" handleClick={() => this.state.selectedOption === '' ? notifyUser("Renew", 'Please select an option.', type.DANGER) : this.setState({ open: false })}><Link to={{
                                                                        pathname: this.state.selectedOption !== '' && `/order/${this.props.user.id}`,
                                                                        state: this.state.selectedOption === 'option1' ? { payfor: 'membership' } : { payfor: 'membershipMonthly' }
                                                                    }} className='payment_text'>RENEW</Link></ButtonStyle>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        (this.props.user.accountType === 'REGISTERED' && this.props.user.message === 'MONTHLY_PREPAID') ?
                                            <div className='popup_height'>
                                                <div className='popup_innerheight' key="message">
                                                    <div className="form-row">
                                                        <div className="col-md-12 text-center location_padding">
                                                            <TitleHeading>
                                                                Make Payment
                                                            </TitleHeading>
                                                        </div>
                                                        <div className="col-md-12 text-center">
                                                            <div className="col-md-12 text-left intermediate_popup_mobile">
                                                                <p>Dear AajuBaajuian,</p>
                                                                <p>Please click on the pay now button to pay the subscription amount to login.</p>

                                                                <div className="row intermediate_popup_padding">
                                                                    <div className="col-sm-12 text-center">
                                                                        <ButtonStyle type="submit" className="btn" handleClick={() => this.setState({ open: false })}><Link to={{
                                                                            pathname: `/order/${this.props.user.id}`,
                                                                            state: { payfor: 'membershipMonthly' }
                                                                        }} className='payment_text'>PAY NOW</Link></ButtonStyle>
                                                                    </div>
                                                                </div>
                                                                <p><strong>Note:</strong> Please do not click the PAY NOW button if you have already made the payment and the payment was successful.
                                                                    Send the payment screenshot to 8341370819 for us to check and activate the account.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='popup_height'>
                                                <div className='popup_innerheight' key="message">
                                                    <div className="form-row">
                                                        <div className="col-md-12 text-center location_padding">
                                                            <TitleHeading>
                                                                Account Expired, Please Upgrade
                                                            </TitleHeading>
                                                        </div>
                                                        <div className="col-md-12 text-center">
                                                            <div className="col-md-12 text-left popup_upgrade">
                                                                <p>Dear AajuBaajuian,</p>
                                                                <p>Please Pay the Annual Subscription of Rs.7080/- to RENEW.</p>
                                                                {this.props.user.gracePeriodExpiryDate !== null ?
                                                                    <p>Your Loyaly Earnings will be expired on {this.props.user.gracePeriodExpiryDate} .</p> : null}
                                                                <p>You will loose your Accumulated Loyalty Earning if you do not renew within 15 days from the date of expiry. The Loyalty earning will become Zero.</p>
                                                                <div className="row intermediate_popup_padding">
                                                                    <div className="col-sm-2 text-right"></div>
                                                                    <div className="col-sm-4 text-center">
                                                                        <ButtonStyle type="submit" className="btn" handleClick={() => this.setState({ open: true })}><Link to={{
                                                                            pathname: `/order/${this.props.user.id}`,
                                                                            state: { payfor: 'membership' }
                                                                        }} className='payment_text'>RENEW</Link></ButtonStyle>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                </Popup>}
                    </div>
                    <div className='locateMobilePopup'>
                        {this.props.user.message === 'PREPAID' || this.props.user.message === 'YEARLY_PREPAID' ?
                            <>
                                <Popup
                                    open={this.state.open}
                                    overlayStyle={{ background: 'rgba(0,0,0,0.95)' }}
                                    closeOnDocumentClick={false}
                                    contentStyle={{ width: "336px", color: 'black', bottom: '10%' }}
                                >
                                    <div className='popup_height'>
                                        <div className='popup_innerheight' key="message">
                                            <div className="form-row">
                                                <div className="col-md-12 text-center location_padding">
                                                    <TitleHeading>
                                                        Make Payment
                                                    </TitleHeading>
                                                </div>
                                                <div className="col-md-12 text-center">
                                                    <div className="col-md-12 text-left">
                                                        <p style={{ fontSize: '12px', fontWeight: '700' }}>Dear AajuBaajuian,</p>
                                                        <p style={{ fontSize: '12px', fontWeight: '700' }}>Please click on the pay now button to pay the subscription amount to login.</p>
                                                        <div className="row intermediate_popup_padding">
                                                            <ul className='col-md-12 text-center'>
                                                                {/* <li className='list-inline-item'><div className="col-sm-4"></div></li> */}
                                                                <li className='list-inline-item'><div className="col-md-12">
                                                                    <ButtonStyle type="submit" className="btn" handleClick={() => this.setState({ open: false })}><Link to={{
                                                                        pathname: `/order/${this.props.user.id}`,
                                                                        state: { payfor: 'membership' }
                                                                    }} className='payment_text'>PAY NOW</Link></ButtonStyle>
                                                                </div></li>
                                                            </ul>
                                                        </div>
                                                        <p><strong>Note:</strong> Please do not click the PAY NOW button if you have already made the payment and the payment was successful.
                                                            Send the payment screenshot to 8341370819 for us to check and activate the account.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Popup>
                            </> : this.props.user.message === 'HALF_YEARLY_PREPAID' && this.props.user.accountType === 'REGISTERED' ?
                                <>
                                    <Popup
                                        open={this.state.open}
                                        overlayStyle={{ background: 'rgba(0,0,0,0.95)' }}
                                        closeOnDocumentClick={false}
                                        contentStyle={{ width: "336px", color: 'black', bottom: '10%' }}
                                    >
                                        <div className='popup_height'>
                                            <div className='popup_innerheight' key="message">
                                                <div className="form-row">
                                                    <div className="col-md-12 text-center location_padding">
                                                        <TitleHeading>
                                                            Make Payment
                                                        </TitleHeading>
                                                    </div>
                                                    <div className="col-md-12 text-center">
                                                        <div className="col-md-12 text-left intermediate_popup_mobile">
                                                            <p>Dear AajuBaajuian,</p>
                                                            <p>Please click on the pay now button to pay the subscription amount to login.</p>

                                                            <div className="row intermediate_popup_padding">
                                                                <div className="col-sm-12 text-center">
                                                                    <ButtonStyle type="submit" className="btn" handleClick={() => this.setState({ open: false })}><Link to={{
                                                                        pathname: `/order/${this.props.user.id}`,
                                                                        state: { payfor: 'membership' }
                                                                    }} className='payment_text'>PAY NOW</Link></ButtonStyle>
                                                                </div>
                                                            </div>
                                                            <p><strong>Note:</strong> Please do not click the PAY NOW button if you have already made the payment and the payment was successful.
                                                                Send the payment screenshot to 8341370819 for us to check and activate the account.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Popup>
                                </> :
                                <Popup
                                    open={this.state.open}
                                    overlayStyle={{ background: 'rgba(0,0,0,0.95)' }}
                                    closeOnDocumentClick={false}
                                    contentStyle={{ width: "336px", color: 'black', bottom: '10%' }}
                                >
                                    {this.props.user.accountType !== 'REGISTERED' && this.props.user.message === 'MONTHLY_PREPAID' ?
                                        <div className='popup_height'>
                                            <div className='popup_innerheight' key="message">
                                                <div className="form-row">
                                                    <div className="col-md-12 text-center location_padding">
                                                        <TitleHeading>
                                                            Account Expired, Please Renew
                                                        </TitleHeading>
                                                    </div>
                                                    <div className="col-md-12 text-center">
                                                        <div className="col-md-12 text-left popup_upgrade_mobile">
                                                            <p style={{ fontSize: '12px', fontWeight: '700' }}>Dear AajuBaajuian,</p>
                                                            <p style={{ fontSize: '12px', fontWeight: '700' }}>Account Expired! Please Renew to Continue.</p>
                                                            {this.props.user.gracePeriodExpiryDate !== null ?
                                                                <p style={{ fontSize: '12px', fontWeight: '700' }}>Your Loyaly Earnings will be expired on {this.props.user.gracePeriodExpiryDate} .</p> : null}
                                                            <p style={{ fontSize: '12px', fontWeight: '700' }}>You will loose your Accumulated Loyalty Earning if you do not renew within 3 days from the date of expiry. The Loyalty income will become Zero.</p>
                                                            <div className='row intermediate_popup_padding'>
                                                                <div className="row col-12 text-left">
                                                                    <div className="form-row col-12 col-md-6">
                                                                        <label className='container_radio col-12 col-md-12 d-flex'>
                                                                            <div className='col-1 col-md-1'><input type="checkbox" name="radio" value="option1" checked={this.state.selectedOption === 'option1'} onChange={this.handleOptionChange} />
                                                                                <span className="checkmark"></span></div>

                                                                            <div className='col-11 col-md-11 p-0'><span className='text-left word-break'>Annual</span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-row col-12 col-md-6">
                                                                        <label className='container_radio col-12 col-md-12 d-flex'>
                                                                            <div className='col-1 col-md-1'><input type="checkbox" name="radio" value="option2" checked={this.state.selectedOption === 'option2'} onChange={this.handleOptionChange} />
                                                                                <span className="checkmark"></span></div>

                                                                            <div className='col-11 col-md-11 p-0'><span className='text-left'>Monthly</span></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row intermediate_popup_padding">
                                                                <div className="col-sm-2 text-right"></div>
                                                                <div className="col-sm-4 text-center">
                                                                    <ButtonStyle type="submit" className="btn" handleClick={() => this.state.selectedOption === '' ? notifyUser("Renew", 'Please select an option.', type.DANGER) : this.setState({ open: false })}><Link to={{
                                                                        pathname: this.state.selectedOption !== '' && `/order/${this.props.user.id}`,
                                                                        state: this.state.selectedOption === 'option1' ? { payfor: 'membership' } : { payfor: 'membershipMonthly' }
                                                                    }} className='payment_text'>RENEW</Link></ButtonStyle>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        (this.props.user.accountType === 'REGISTERED' && this.props.user.message === 'MONTHLY_PREPAID') ?
                                            <div className='popup_height'>
                                                <div className='popup_innerheight' key="message">
                                                    <div className="form-row">
                                                        <div className="col-md-12 text-center location_padding">
                                                            <TitleHeading>
                                                                Make Payment
                                                            </TitleHeading>
                                                        </div>
                                                        <div className="col-md-12 text-center">
                                                            <div className="col-md-12 text-left intermediate_popup_mobile">
                                                                <p>Dear AajuBaajuian,</p>
                                                                <p>Please click on the pay now button to pay the subscription amount to login.</p>

                                                                <div className="row intermediate_popup_padding">
                                                                    <div className="col-sm-12 text-center">
                                                                        <ButtonStyle type="submit" className="btn" handleClick={() => this.setState({ open: false })}><Link to={{
                                                                            pathname: `/order/${this.props.user.id}`,
                                                                            state: { payfor: 'membershipMonthly' }
                                                                        }} className='payment_text'>PAY NOW</Link></ButtonStyle>
                                                                    </div>
                                                                </div>
                                                                <p><strong>Note:</strong> Please do not click the PAY NOW button if you have already made the payment and the payment was successful.
                                                                    Send the payment screenshot to 8341370819 for us to check and activate the account.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='popup_height'>
                                                <div className='popup_innerheight' key="message">
                                                    <div className="form-row">
                                                        <div className="col-md-12 text-center location_padding">
                                                            <TitleHeading>
                                                                Account Expired, Please Upgrade
                                                            </TitleHeading>
                                                        </div>
                                                        <div className="col-md-12 text-center">
                                                            <div className="col-md-12 text-left popup_upgrade_mobile">
                                                                <p style={{ fontSize: '12px', fontWeight: '700' }}>Dear AajuBaajuian,</p>
                                                                <p style={{ fontSize: '12px', fontWeight: '700' }}>Please Upgrade to Continue.</p>
                                                                {this.props.user.gracePeriodExpiryDate !== null ?
                                                                    <p style={{ fontSize: '12px', fontWeight: '700' }}>Your Loyaly Earnings will be expired on {this.props.user.gracePeriodExpiryDate} .</p> : null}
                                                                <p style={{ fontSize: '12px', fontWeight: '700' }}>You will loose your Accumulated Loyalty Earning if you do not renew within 15 days from the date of expiry. The Loyalty income will become Zero.</p>
                                                                <div className="row intermediate_popup_padding">
                                                                    <div className="col-sm-2 text-right"></div>
                                                                    <div className="col-sm-4 text-center">
                                                                        <ButtonStyle type="submit" className="btn" handleClick={() => this.setState({ open: true })}><Link to={{
                                                                            pathname: `/order/${this.props.user.id}`,
                                                                            state: { payfor: 'membership' }
                                                                        }} className='payment_text'>RENEW</Link></ButtonStyle>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                </Popup>}
                    </div>
                </>
            </PageVisibility>
        )
    }
}

const mapStateToProps = state => {
    return {
        viewedAds: state.viewedAds,
        is_login: state.is_login,
        visibleAds: state.visibleAds,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        notifyPopupOpen: (isPopupOpen) => dispatch({ type: 'POPUP_OPEN_NOTIFICATION', isPopupOpen: isPopupOpen }),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Upgrade));
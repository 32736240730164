import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import connect from '../../assets/connect.jpg';
import vision from '../../assets/vision.svg';
import regisrec from '../../assets/regisrec.jpg';
import indianPlatform from '../../assets/indianPlatform.svg'

export const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div class="grid_container terms_of_service">
            <div class="row">
                <div class="col-12">
                    <h1 className={`${FooterStyle.footer_links_header} text-center`}>About <span className={FooterStyle.logo_saffron}>aaju</span><span className={FooterStyle.logo_green}>baaju</span><span className={FooterStyle.logo_blue}>.in</span> - A SaaS Platform by AajuBaaju eClassifieds Private Limited</h1>
                    <p>aajubaaju.in is a subscription-based Software as a Service (SaaS) platform developed by AajuBaaju eClassifieds Private Limited, a company incorporated in Telangana on July 17, 2019. Recognized by both Startup India and StartupTelangana, the platform is designed to empower Indians in the online advertisement industry.</p>
                    <h2>The subscribers of <span className={FooterStyle.logo_saffron}>aaju</span><span className={FooterStyle.logo_green}>baaju</span><span className={FooterStyle.logo_blue}>.in</span> are called as “<span className={FooterStyle.logo_saffron}>Aaju</span><span className={FooterStyle.logo_green}>Baaju</span><span className={FooterStyle.logo_blue}>ians</span>”</h2>

                    <h2 className={FooterStyle.abtH2}>VISIONS:</h2>
                    <ul>
                        <h2>Economic Empowerment:</h2>
                        <li>To provide every Indian with a stable, recession- and lockdown-proof online income through the power of online advertising.</li>
                        <h2>"India ka Apna Website":</h2>
                        <li>To create a homegrown alternative for Indian content creators and advertisers, supporting local businesses and talent.</li>
                        <h2>Support for Small Businesses:</h2>
                        <li>Offering tiny businesses a comprehensive online promotional tool that allows them to post unlimited free pages, videos, and photos.</li>
                    </ul>

                    <h2 className={FooterStyle.abtH2}>CONCEPT:</h2>
                    <p>aajubaaju.in aims to increase consumer purchasing power through the online advertisement industry.It encourages AajuBaajuians to empower themselves, others, and the country, ultimately working towards the vision of a "developed India."</p>
                    <h2 className='text-center'>Empower Yourself; Empower Others; Empower India.</h2>
                    <h2 className='text-center'>Let our children start to read<br></br>
                        "India is a developed country"
                    </h2>

                    <h2 className={FooterStyle.abtH2}>MOTTO:</h2>
                    <p>Every Effort Made & Every Minute Spent to Earn Money Online Should Never Go Waste. Earn from the first content from the first day.</p>

                    <h2 className={FooterStyle.abtH2}>SLOGAN:</h2>
                    <p>"AmeereeBadaao" (Increase Wealth)</p>

                    <h2 className={FooterStyle.abtH2}>INITIATIVE:</h2>
                    <p><strong>“INDIA ka Apna Website”</strong><br></br>
                        aajubaaju.in is built on the motive to contribute towards "Self Reliant India"; to provide an alternate online platform for Indian content creators and online advertisers. aajubaaju.in is a combination of user-generated content websites such as video streaming, photo story, blog, and review writing; combination of online directory websites such as business listing, event listing, and job posting websites. "A 7-in-1 Website."</p>

                    <h2 className={FooterStyle.abtH2}>LOCALIZED SEARCH PLATFORM:</h2>
                    <p>aajubaaju.in is a highly localized information provider, designed to source and present neighbourhood-level information,using the crowdsourcing model "For the Indians, By the Indians”. The platform will offer hyper-local informationacross seven segments: videos, photos, pages, reviews, businesses, events, and jobsfrom a single search.</p>

                    <h2 className={FooterStyle.abtH2}>HOME SWEET HOME IS THE MANTRA:</h2>
                    <p><strong><span className={FooterStyle.logo_saffron}>aaju</span><span className={FooterStyle.logo_green}>baaju</span><span className={FooterStyle.logo_blue}>.in</span> aids "Vocal for Local".</strong></p>
                    <p>aajubaaju.in focuses on the neighbourhood of a visitor. A visit to aajubaaju.in is designed to be an online tour of their neighbourhood. The visitors will see all the businesses around them, upcoming events around them, jobs around them, & advertisements of the businesses around them. The visitors will be seeing all the information which can be used by them in their day to day life. The information will not just an useful information, but also usable information.</p>

                    <h2 className={FooterStyle.abtH2}>ONLINE INCOME OPPORTUNITY FOR EVERYONE:</h2>
                    <p>aajubaaju.in has developed a unique strategyto provide the subscribers a "Recession & Lockdown Proof Stable Online Income Source" from the online advertisement industry. Anyone, according to their skill, can contribute content in one or more segments & earn from the advertisements displayed in their content.</p>
                    <p>aajubaaju.in also has a strategy to those without specific skills to make an online income by engaging with the advertisements.</p>

                    <h2 className={FooterStyle.abtH2}>DIGITAL ENTREPRENEUR:</h2>
                    <p>aajubaaju.inprovides digital entrepreneurship opportunities with its "Ab Sab ka Skills HaiApna Skills" 2 level loyalty program, allowing users to generate passive income.</p>
                    <ul>
                        <li>Level 1 enables an AajuBaajuian to earn advertisement revenue share from others' content creation skills apart from earning from one's own skill. A video content creator can onboard a content writer, a photo story creator, a review writer, etc., to earn advertisement revenue share from their skills.</li>
                        <li>Level 2 enables an AajuBaajuian to utilize others' socializing & influencing skills to increase their community size of other content creators.</li>
                    </ul>

                    <h2 className={FooterStyle.abtH2}>SUPPORT FOR TINY BUSINESSES:</h2>
                    <p>Problem: The present online platforms for advertisements are either complex or expensive or both for the tiny businesses to use the power of online advertising to improve their business.</p>
                    <p>Solution: aajubaaju.in is suitable for tiny businesses to promote their business online within their locality with unlimited pages, unlimited photos & unlimited videos at zero cost.</p>

                    <h2 className={FooterStyle.abtH2}>"Spend No More; Just Earn More"</h2>
                    <p>aajubaaju.in has a "Spend No More; Just Earn More" concept for business promotion. Apart from getting online visibility, the businesses can earn advertisement revenue share from their promotions itself to meet their promotional cost.</p>

                    <h2 className={FooterStyle.abtH2}>NO MORE HARDSHIPS TO SETUP AN ONLINE INCOME:</h2>
                    <p>aajubaaju.in has a motto of "Every Effort Made & Every Minute Spent to Earn Money Online Should Never Go Waste."</p>
                    <p>aajubaaju.in has researched and eliminated the common barriers that content creators face on other platforms causing wastage of their effort and time spent to create the content.</p>

                    <h2 className={FooterStyle.abtH2}>ELIMINATED THE HARDSHIPS OF VIDEO CONTENT CREATORS:</h2>
                    <p>Problem: The major hardship for the video content creators are the monetization rules prevailing in the leading advertisement revenue sharing platforms.</p>
                    <p>Solution: aajubaaju.in has no monetization rules such as minimum subscribers/page likes, minimum watch hours/followers, etc. Video content creators can post their video content in "AajuBaaju My Videos& AajuBaaju My Clips" segments and can start earning advertisement revenue share from their first video from the first day onwards.</p>
                    <p>A video content creating AajuBaajuian can onboard other video content creators from multiple different niches and earn advertisement revenue share from their videos as well, as part of loyalty earning program. An AajuBaajuian posting videos in education niche can onboard video content creator from tech niche, beauty niche, cooking niche, etc., and earn loyalty earning.</p>

                    <h2 className={FooterStyle.abtH2}>ELIMINATED THE HARDSHIPS OF CONTENT WRITERS:</h2>
                    <p>Problem: The major hardships for the creative content writers are maintaining a website, researching keywords & SEO to drive traffic to their website, etc. There are many content writers who are unable to use their writing skill to earn money online because of these unwanted hurdles.</p>
                    <p>Solution: Creative content writers can post their articles and blog content in "AajuBaaju My Pages" segment and can start earning advertisement revenue share from their first content from the first day onwards.</p>
                    <p>Creative content writers can concentrate only on the content writing now, their real skill.</p>
                    <p>A creative content writing AajuBaajuian can onboard other creative content writers from multiple different niches and earn money from their writing as well, as part of the loyalty earning program. An AajuBaajuian writing in education niche can onboard content writers from tech niche, beauty niche, cooking niche, etc., and earn loyalty income.</p>

                    <h2 className={FooterStyle.abtH2}>NEW ADVERTISEMENT REVENUE SHARING SEGMENTS:</h2>
                    <p>Problem: The present online earning opportunities requires specific skill sets to make an online income. There is a large portion of population who do not have these specific skills.</p>
                    <p>Solution: aajubaaju.in has added additional advertisement revenue sharing segments to cover additional skill sets.</p>
                    <p>aajubaaju.in has "AajuBaaju My Photos" segment in which an AajuBaajuian can create a photo story out of the photos taken in a vacation, function, event, etc., or create product catalogues for their business and earn revenue share from the advertisements displayed in their photo story.</p>
                    <p>aajubaaju.in has "AajuBaaju My Album" segment in which an AajuBaajuian can create a personal photo album out of the photos taken to capture memories and earn revenue share from the advertisements displayed in their photo album.</p>
                    <p>aajubaaju.in has "AajuBaaju My Reviews" segment in which an AajuBaajuian can write review about products, places, businesses, etc., and earn revenue share from the advertisements displayed in their review.</p>
                    <p>aajubaaju.in has "Business Listings" segment in which an AajuBaajuian can list businesses, service providers, etc., and earn revenue share from the advertisements displayed in their business listing.</p>
                    <p>aajubaaju.in has "Event Listings" segment in which an AajuBaajuian can list upcoming events and earn revenue share from the advertisements displayed in their event listing.</p>
                    <p>aajubaaju.in has "Job Listings" segment in which an AajuBaajuian can list job vacancies and earn revenue share from the advertisements displayed in their job listing.</p>

                    <h2 className={FooterStyle.abtH2}>EARN BY WATCHING ADVERTISEMENTS:</h2>
                    <p>Problem: There is a vast portion of population still remaining who cannot make use of these 7 segments.</p>
                    <p>Solution: aajubaaju.in has created an "Easy to Learn" strategy to earn by watching advertisement to cover this portion of population with zero skills.</p>

                    <h2 className={FooterStyle.abtH2}>NO MINIMUM WITHDRAWAL CRITERIA*:</h2>
                    <p>aajubaaju.inalso allows <strong style={{textDecoration: 'underline'}}>*ELIGIBLE</strong> AajuBaajuians (check for the eligibility) to withdraw their earnings with no minimum threshold. Earnings can be withdrawn daily, weekly, or at any frequency of choice.</p>

                    <h2 className={FooterStyle.abtH2}>AN INDIAN PLATFORM:</h2>
                    <p>Most Indian websites, whether large or small, government or non-government, only feature icons for Facebook, X, YouTube, and LinkedIn in their "Follow Us" sections. Even the Aatmanirbhar Bharat webpage has to rely on these platforms.</p>

                    <div className='text-center'><img className="img-fluid" src={indianPlatform} /></div> 
                    <p>aajubaaju.in is a true Indian alternative. Proud Indians can showcase their businesses or personal brands on a platform that belongs to India.</p>

                    <div className='text-center p-1'>
                        <h1 className={`${FooterStyle.footer_links_header} text-center`}><span className={FooterStyle.logo_saffron}>aaju</span><span className={FooterStyle.logo_green}>baaju</span><span className={FooterStyle.logo_blue}>.in</span></h1>
                        <span className={FooterStyle.spanCenter}>in alignment with India’s</span> <br></br><span className={FooterStyle.spanCenter}>Vision.</span>
                    </div>
                    <div className='text-center'><img className="img-fluid" src={vision} /></div>
                    <p className='text-center' style={{ fontSize: '26px' }}>In summary, aajubaaju.in is a comprehensive, localized platform designed to empower Indian individuals and small businesses with accessible tools for online income and growth.</p>
                    <h1 className={`${FooterStyle.footer_links_header} text-center`}>Democratizing<br></br>
                        Digital<br></br>
                        Entrepreneurship
                    </h1>
                    {/* <div className='text-center p-1'><img className="img-fluid" src={connect} /></div>
                    <div className='text-center p-1'><img className="img-fluid" src={regisrec} /></div><br></br> */}
                </div>
                <div className="listings_footer row">
                    <div className={`${styles.large_rect}`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard}`}>
                        <BillBoard spot={"-1"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

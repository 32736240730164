import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import authservice from '../service/authservice';
import { container, type, notifyUser } from '../../utility/notifyUser';
import adService from '../../advertisements/service/adService';
import styles from '../authentication.module.css';
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';
import Upgrade from '../../Upgrade';
import { message } from 'antd';
import fullEmployment from '../../../assets/fullEmployment.jpg'
import ablogo from '../../../assets/ablogo.svg'

export const Login = (props) => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [varifying, setVerifying] = useState(false);
    const [expiryInfo, setExpiryInfo] = useState(null);
    const [showPassword, setShowPassword] = useState(false);


    const dispatch = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0); //while loading the component it will focus top
        if (authservice.getCurrentUser() !== null)
            props.history.push('/')
    }, [])

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        setVerifying(true)
        axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/login', { userName: userName.trim(), password: password }).then(response => {
            //if (response.data.status === 'OTP_VERIFICATION')
            // props.history.push({
            //     pathname: `/otp/${response.data.id}`,
            //     search: `?auth_mod=${response.data.mobileNumber}`,
            //     state: { email: response.data.email }
            // })
            // else if (response.data.status === 'EMAIL_VERIFICATION') {
            //     props.history.push({
            //         pathname: `/resendemail/${response.data.id}`,
            //         search: `?auth_mod=${response.data.email}`
            //     })
            // } 
            //else {
            // if (response.data.id)
            //     axios.post(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/logout?userId=" + response.data.id).catch(err => {
            //         console.log(err)
            //     })
            //localStorage.clear()

            const isAdmin = response.data.accountType === 'ADMIN' || response.data.accountType === 'SUPER_ADMIN'
            if (isAdmin) {
                response.data.loginDate = new Date();
                localStorage.setItem('user', JSON.stringify(response.data));
                //dispatch({ type: 'IS_LOGIN', is_login: true })
                dispatch({ type: 'ADMIN_LOGIN', isAdmin: isAdmin })
                window.location.reload()
                props.history.push('/admin-panel/dashboard')
            }
            if (response.data.id) {
                axios.post(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/logout?userId=" + response.data.id).catch(err => {
                    console.log(err)
                })
                //     props.history.push({
                //         pathname: `/login-otp/${response.data.id}`,
                //         search: `?auth_mod=${response.data.mobileNumber}`,
                //         userData: response.data
                //     })
                // }
                response.data.loginDate = new Date();
                localStorage.setItem('user', JSON.stringify(response.data));
                dispatch({ type: 'IS_LOGIN', is_login: true })
                //const isAdmin = response.data.accountType === 'ADMIN' || response.data.accountType === 'SUPER_ADMIN'
                if (isAdmin) {
                    dispatch({ type: 'ADMIN_LOGIN', isAdmin: isAdmin })
                    props.history.push('/admin-panel/dashboard')
                }
                else {
                    const visitor = {
                        deviceId: authservice.getCookie('hp.rocks.soon'),
                        userId: response.data.id,
                    }
                    // props.history.push('/')
                    axios.post(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/visitor", visitor)
                    adService.getViewedAds(response.data).then(data => {
                        dispatch({ type: 'ALL_VIEWED_ADS', viewedAds: [...data] })
                        if (props.match.params.required === 'user')
                            props.history.push('/')
                        else
                            props.history.goBack();
                    })
                }
            }
            //}
        }).then(null, error => {
            console.log(error.response)
            if (error.response.status == 403 && error.response.data && (error.response.data.message === 'ACOUNT HAS EXPIRED' || error.response.data.message === 'PREPAID' || error.response.data.message === 'MONTHLY_PREPAID' || error.response.data.message === 'HALF_YEARLY_PREPAID' || error.response.data.message === 'YEARLY_PREPAID')) {
                const userData = error.response.data;
                setExpiryInfo(userData);
            } else {
                switch (error.response.data) {
                    case 'FAILD_LOGIN_ATTEMPT_1':
                        notifyUser('Failed Attempt 1', 'Please try again. You have 3 attempts left', type.WARNING, container.BOTTOM_RIGHT);
                        break;
                    case 'FAILD_LOGIN_ATTEMPT_2':
                        notifyUser('Failed Attempt 2', 'Please try again. You have 2 attempts left', type.WARNING, container.BOTTOM_RIGHT);
                        break;
                    case 'FAILD_LOGIN_ATTEMPT_3':
                        notifyUser('Failed Attempt 3', 'Please try again. You have 1 attempt left', type.WARNING, container.BOTTOM_RIGHT);
                        break;
                    case 'LOCKED':
                        notifyUser('LOCKED', 'Account is Locked Due to Incorrect Password. Please Contact support@aajubaaju.in', type.INFO, container.BOTTOM_RIGHT);
                        break;
                    default:
                        notifyUser('Failed login', error.response.data, type.DANGER, container.BOTTOM_RIGHT);
                        break;
                }
            }
            setVerifying(false)
        }).catch(error => {
            console.log(error.response)
            notifyUser("", 'An unexpected error occured', "danger");
            setVerifying(false)
        });

    })

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            {expiryInfo !== null && <Upgrade user={expiryInfo} />}
            {/* <div className="container-fluid" style={{ padding: '2%' }}> */}
                <div className="row p-lg-4 text-center">
                    <div className="col-12 col-lg-9 text-left">
                        <img
                            src={fullEmployment}
                            alt="Advertisement Space"
                            className="img-fluid"
                            style={{ width: '1200px', height: "auto" }}
                        />
                    </div>
                    <div className='col-12 col-lg-3 d-block d-md-none' style={{ paddingTop: '2.5%' }}></div>
                    <div className="col-12 col-lg-3 text-center">
                        <form onSubmit={handleSubmit} className="p-4 border rounded bg-white shadow">
                            <Link to="/">
                                <img
                                    src={ablogo}
                                    alt="AajuBaaju Logo"
                                    className="img-fluid"
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </Link>
                            <h2 className="text-center mb-6">Login</h2>

                            <div className="form-group row text-left d-none d-md-block">
                                <label htmlFor="username" className="col-12 col-md-3 col-form-label">
                                    Username
                                </label>
                            </div>
                            <div className="form-group row">
                                <div className="col-12 col-md-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Username"
                                        name="username"
                                        required
                                        onChange={event => setUserName(event.target.value)}
                                        noValidate
                                    />
                                </div>
                            </div>

                            <div className="form-group row text-left d-none d-md-block">
                                <label htmlFor="password" className="col-12 col-md-3 col-form-label">
                                    Password
                                </label>
                            </div>
                            <div className="form-group row">
                                <div className="col-12 col-md-12">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className="form-control"
                                        placeholder="Password"
                                        name="password"
                                        required
                                        onChange={event => setPassword(event.target.value)}
                                        noValidate
                                    />
                                    <i
                                        className={`password-toggle fas fa-eye${showPassword ? "-slash" : ""}`}
                                        onClick={togglePasswordVisibility}
                                    />
                                </div>
                            </div>

                            <div className="form-group row justify-content-center" style={{ padding: '8%' }}>
                                <div className="col-12 text-center">
                                    <ButtonStyle type="submit" className="btn btn-primary w-50">
                                        {varifying ? <i className="fas fa-circle-notch fa-spin"></i> : "Login"}
                                    </ButtonStyle>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-12 text-center">
                                    <Link to="/forgotpassword">Forgot Password?</Link>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12 text-center">
                                    <Link to="/registration">Don't have an account?<br></br>REGISTER</Link>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="form-group row">
                                <div className="col-12 text-center">
                                    <Link to="/contact">Contact Support</Link> &nbsp; | &nbsp; <Link to="/">Back to Home</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            {/* </div> */}
        </>
    )
}
import React, { useEffect, useRef } from 'react';
import TextDecoration from '../utility/TextDecoration';
import styles from './cover.module.css';
import { useViewport } from '../utility/useViewport';
import listingService from '../listings/service/listingService';

export const VideoCardData = (props) => {
    const listing = props.listing;
    const thumb = listing.thumbnailImage ? listing.thumbnailImage : 'assets/Desert.jpg';
    const isMobile = useViewport();

    let isViewCounted = false;
    const timeOut = useRef(null);
    const handleClick = () => {
        if (!isViewCounted) countView();
        props.showContent(listing, props.section);
    };
    const countView = () => {
        if (timeOut.current) clearTimeout(timeOut.current);
        isViewCounted = true;
        //listingService.updateSingleListingViews(props.section, listing);
    };
    useEffect(() => {
        return () => {
            if (timeOut.current) clearTimeout(timeOut.current);
        };
    }, [timeOut]);

    return (
        <>
            {listing.listingType === 'article' ? (
                <div className="col-12 col-sm-6 col-md-3 p-2 curser_hand">
                    <div className={`${styles.listing_card_video} card h-100`} onClick={handleClick}>
                        <div className={`${styles.listing_thumb_image_video} card-img-top`}>
                            <img
                                className="img-fluid w-100"
                                loading="lazy"
                                src={process.env.REACT_APP_IMAGES_URL + thumb}
                                style={{ height: 'auto' }}
                                alt={listing.businessName}
                            />
                        </div>
                        <div className="card-body">
                            <h6 className={`card-title ${isMobile ? styles.mob_listing_title_video : styles.listing_title_video}`}>
                                {TextDecoration.ellipsize(listing.businessName, 65)}
                            </h6>
                            <div className="card-text">
                                <div className={styles.listing_info_box_desc_newsArt_video}>
                                    {TextDecoration.ellipsize(listing.specializedIn, 130)}
                                </div>
                                <div className={styles.basicVideoSubCat}>
                                    <span className={styles.name_span}>By:</span>
                                    {TextDecoration.ellipsize(listing.subCategories, 45)}
                                </div>
                            </div>
                            {props.isViewed && <i className="fas fa-check-circle viewed_mark"></i>}
                        </div>
                    </div>
                </div>
            ) : listing.listingType === 'clips' ? (
                <div className={`card curser_hand ${styles.clip_card}`} onClick={() => handleClick(listing)}>
                    <div className={`${styles.listing_thumb_image_video} card-img-top`}>
                        <img className="img-fluid w-100" loading="lazy" src={process.env.REACT_APP_IMAGES_URL + thumb} alt={listing.businessName} />
                    </div>
                    <div className={`card-body text-left ${styles.clip_description}`}>
                        <h6 className={`card-title ${isMobile ? styles.mob_listing_title_video : styles.clip_title_video}`}>
                            {TextDecoration.ellipsize(listing.businessName, 50)}
                        </h6>
                        <div className={styles.listing_info_box_desc_newsArt_video} style={{ color: '#fff' }}>
                            <span className={styles.name_span}>By:</span> {TextDecoration.ellipsize(listing.subCategories, 45)}
                        </div>
                    </div>
                    {props.isViewed && <i className="fas fa-check-circle viewed_mark"></i>}
                </div>
            ) : null}
        </>
    );
};
